<template>
    <div class="stepper">
        <div class="progressbar-container">
            <ul class="progressbar">
                <li
                    v-for="(step, i) in steps"
                    :class="{
                        active: isActive(step, i),
                        ['current font-weight-bold']: isCurrent(step, i),
                    }"
                    :key="i"
                >
                    {{ get(step, 'translation', '') }}
                </li>
            </ul>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <b-button
            v-if="!isFirstStep"
            @click="onPreviousStep"
            :disabled="previousButtonDisabled"
            variant="primary"
            class="btn-block-xs-only mb-2 mb-sm-0 float-left"
        >
            {{ t('stepper_Previous') }}
        </b-button>
        <b-button
            v-if="!isLastStep"
            @click="onNextStep"
            :disabled="nextButtonDisabled"
            variant="primary"
            class="btn-block-xs-only float-right"
        >
            {{ t('stepper_Next') }}
        </b-button>
    </div>
</template>

<script>
import {get} from 'lodash';

export default {
    name: 'Stepper',
    props: {
        value: {
            type: [Number, Object],
            default: null,
        },
        steps: {
            type: [Number, Array],
            default: () => [],
        },
        nextButtonDisabled: {
            type: Boolean,
            default: false,
        },
        previousButtonDisabled: {
            type: Boolean,
            default: false,
        },
        onBeforeNext: {
            type: Function,
            default: () => () => true,
        },
    },
    data() {
        return {};
    },
    computed: {
        /**
         * Determines if the current step is the first step
         */
        isFirstStep() {
            const minIndex = this.getFirstStepIndex();
            if (Array.isArray(this.steps)) {
                return get(this.value, 'index', this.value) === minIndex;
            } else {
                return this.steps === minIndex;
            }
        },
        /**
         * Determines if the current step is the last step
         */
        isLastStep() {
            if (Array.isArray(this.steps)) {
                return get(this.value, 'index', this.value) >= this.steps.length - 1;
            } else {
                return this.steps >= this.value;
            }
        },
    },
    watch: {
        steps: {
            handler: function () {},
            deep: true,
            immediate: true,
        },
    },
    methods: {
        get,
        /**
         * Gets the index of the first step
         */
        getFirstStepIndex() {
            if (Array.isArray(this.steps)) {
                const indexes = this.steps.map((s) => s.index);
                return Math.min(...indexes);
            } else {
                return Math.min(...this.steps);
            }
        },
        isCurrent(current, iterator) {
            return get(current, 'index', iterator) === get(this.value, 'index', this.value);
        },
        isActive(current, iterator) {
            return get(current, 'index', iterator) <= get(this.value, 'index', this.value);
        },
        /**
         * Handles movement to the next step
         */
        onNextStep() {
            if (!this.onBeforeNext()) return;

            if (Array.isArray(this.steps)) {
                this.$emit('input', this.steps[get(this.value, 'index', this.value) + 1]);
            } else {
                this.$emit('input', this.value + 1);
            }
        },
        /**
         * Handles movement to the previous step
         */
        onPreviousStep() {
            if (Array.isArray(this.steps)) {
                let currentIndex = get(this.value, 'index', this.value);
                if (currentIndex > this.getFirstStepIndex()) {
                    this.$emit('input', this.steps[currentIndex - 1]);
                } else {
                    return;
                }
            } else {
                if (this.value > this.getFirstStepIndex()) {
                    this.$emit('input', this.value - 1);
                } else {
                    return;
                }
            }
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.stepper {
    position: relative;
}
.progressbar-container {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: table;
}
.progressbar {
    list-style: none;
    counter-reset: step;
    display: table-row;
}
.progressbar li {
    position: relative;
    text-align: center;
    display: table-cell;
}
.progressbar li:before {
    content: '';
    width: 15px;
    height: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 1.9rem;
    background: #fefefe;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
}
.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #0000001a;
    top: 6px;
    left: 0%;
    z-index: -1;
}
.progressbar li.active:before {
    border-color: #c2c9d1;
    background: #c2c9d1;
}
.progressbar li.current:before {
    border-color: #10a8ba;
    background: #10a8ba;
}
.progressbar li:first-child,
.progressbar li:first-child:before {
    text-align: left;
    margin-left: 0px;
}
.progressbar li:last-child,
.progressbar li:last-child:before {
    text-align: right;
    margin-right: 0px;
}
.content {
    padding-top: 80px;
}
</style>
