<template>
    <section class="checkout pb-5">
        <Stepper
            v-model="step"
            :steps="steps"
            :next-button-disabled="stepperButtonsDisabled"
            :previous-button-disabled="stepperButtonsDisabled"
            :on-before-next="shouldContinue"
        >
            <div v-if="$route.name === 'ShippingDetails' || $route.name === 'BillingDetails'">
                <router-view
                    :key="routerViewProps.key"
                    :ref="routerViewProps.ref"
                    :title="routerViewProps.title"
                    :noteLabel="routerViewProps.noteLabel"
                    :showDestination="routerViewProps.showDestination"
                    :showResponsibleParty="routerViewProps.showResponsibleParty"
                    :value="routerViewProps.modelValue"
                    @input="routerViewProps.onInput(routerViewProps.ref, $event)"
                >
                </router-view>
            </div>
            <div v-if="$route.name === 'PaymentDetails'">
                <router-view
                    :key="routerViewProps.key"
                    :ref="routerViewProps.ref"
                    :value="routerViewProps.modelValue"
                    @input="routerViewProps.onInput(routerViewProps.ref, $event)"
                >
                </router-view>
            </div>
            <div v-if="$route.name === 'ReviewConfirm'">
                <router-view
                    :key="routerViewProps.key"
                    :ref="routerViewProps.ref"
                    :value="routerViewProps.modelValue"
                    :was-order-placed.sync="wasOrderPlaced"
                    :place-order-button-disabled="stepperButtonsDisabled"
                    @input="routerViewProps.onInput('agreedToTermsAndConditionsDate', $event)"
                >
                </router-view>
            </div>
        </Stepper>
    </section>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import {debounce} from 'lodash';
import Stepper from '@/components/Stepper.vue';
import i18n from '@/plugins/i18n';

const STEPS_INDEX = {
    SHIPPING_DETAILS: 0,
    BILLING_DETAILS: 1,
    PAYMENT_DETAILS: 2,
    REVIEW_CONFIRM: 3,
};

const STEPS = [
    {
        index: STEPS_INDEX.SHIPPING_DETAILS,
        title: 'Shipping details',
        translation: i18n.i18next.t('checkout_ShippingDetails'),
        route: {
            name: 'ShippingDetails',
        },
    },
    {
        index: STEPS_INDEX.BILLING_DETAILS,
        title: 'Billing details',
        translation: i18n.i18next.t('checkout_BillingDetails'),
        route: {
            name: 'BillingDetails',
        },
    },
    {
        index: STEPS_INDEX.PAYMENT_DETAILS,
        title: 'Payment details',
        translation: i18n.i18next.t('checkout_PaymentDetails'),
        route: {
            name: 'PaymentDetails',
        },
    },
    {
        index: STEPS_INDEX.REVIEW_CONFIRM,
        title: 'Review & confirm',
        translation: i18n.i18next.t('checkout_ReviewConfirm'),
        route: {
            name: 'ReviewConfirm',
        },
    },
];

export default {
    name: 'Checkout',
    components: {
        Stepper,
    },
    data() {
        return {
            step: null,
            wasOrderPlaced: false,
            stepperButtonsDisabled: false,
        };
    },
    computed: {
        ...mapState({
            shoppingCart: (state) => state.cart.shoppingCart,
        }),
        ...mapGetters({
            orderTotal: 'cart/orderTotal',
            itemsCount: 'cart/itemsCount',
            zone: 'zone/currentZone',
        }),
        steps() {
            return STEPS;
        },
        routerViewProps() {
            if (this.$route.name === 'ShippingDetails') {
                return {
                    key: this.$route.name,
                    ref: 'shippingDetails',
                    title: this.t('checkout_ShippingDetails'),
                    noteLabel: this.t('checkout_DeliveryInstructions'),
                    showDestination: true,
                    showResponsibleParty: false,
                    modelValue: this.shoppingCart.shippingDetails,
                    onInput: this.setShoppingCart,
                };
            } else if (this.$route.name === 'BillingDetails') {
                return {
                    key: this.$route.name,
                    ref: 'billingDetails',
                    title: this.t('checkout_BillingDetails'),
                    noteLabel: this.t('checkout_BillingInstructions'),
                    showDestination: false,
                    showResponsibleParty: true,
                    modelValue: this.shoppingCart.billingDetails,
                    onInput: this.setShoppingCart,
                };
            } else if (this.$route.name === 'PaymentDetails') {
                return {
                    key: this.$route.name,
                    ref: 'paymentDetails',
                    modelValue: this.shoppingCart.paymentDetails,
                    onInput: this.setShoppingCart,
                };
            } else if (this.$route.name === 'ReviewConfirm') {
                return {
                    key: this.$route.name,
                    ref: 'reviewConfirm',
                    modelValue: this.shoppingCart.agreedToTermsAndConditionsDate,
                    onInput: this.setShoppingCart,
                };
            } else {
                return null;
            }
        },
    },
    watch: {
        $route(to, from) {
            this.step = this.steps.find((s) => s.route.name === to.name);
        },
        step: {
            deep: true,
            handler: function (step) {
                this.$router.replace({name: step.route.name});
            },
        },
        shoppingCart: {
            deep: true,
            handler: function () {
                this.stepperButtonsDisabled = true;
                this.updateShoppingCart();
            },
        },
    },
    methods: {
        ...mapActions({
            fetchAddresses: 'customers/fetchAddresses',
            fetchItemsCount: 'cart/fetchItemsCount',
            fetchItems: 'cart/fetchItems',
            updateCheckout: 'cart/updateCheckout',
        }),
        /**
         * Sets a property of the shopping cart
         *
         * @param {String} property
         * @param {Object} propertyValue
         */
        setShoppingCart(property, propertyValue) {
            this.shoppingCart[property] = propertyValue;
        },
        /**
         * Determines whether the user can proceed to the next step of the
         * checkout process based on the current step index.
         *
         * @return {Boolean} Returns true if the user can proceed to the next
         *   step, or false if there are validation errors that need to be
         *   fixed.
         */
        shouldContinue() {
            if (this.step.index === STEPS_INDEX.SHIPPING_DETAILS) {
                return this.$refs.shippingDetails.isValid();
            } else if (this.step.index === STEPS_INDEX.PAYMENT_DETAILS) {
                return this.$refs.paymentDetails.isValid();
            } else {
                return true;
            }
        },
        updateShoppingCart: debounce(async function () {
            /**
             * we need to handle this edge case because when we make the call to update
             * the cart to its new value when an order was placed the watch is still active
             * and returns the value to its previous state
             */
            if (this.wasOrderPlaced) return;
            try {
                const {success, error} = await this.updateCheckout(this.shoppingCart);
                await this.fetchAddresses(true);
                this.stepperButtonsDisabled = false;
                if (!success) throw error;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('checkout_Error'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        }, 500),
    },
    mounted() {
        if (!this.itemsCount) {
            this.$router.push({
                name: 'NotFound',
            });
            return;
        } else {
            this.step = this.steps.find((s) => s.route.name === this.$route.name);
        }
    },
};
</script>
<style lang="scss" scoped>
.readonly-border {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}
</style>
